
<script>
// import vue2Dropzone from "vue2-dropzone";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Master from "@/apis/Master";
import {required} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

// import v from "@/apis/vue@next";

// import axios from "axios";
// import Multiselect from "vue-multiselect";
// import VueUploadMultipleImage from 'vue-upload-multiple-image'
// import Dropdown from 'vue-simple-search-dropdown'
// import axios from "axios";
// import VueSimpleSuggest from 'vue-simple-suggest'
// import 'vue-simple-suggest/dist/styles.css'
import Vue from "vue";


// Vue.use(Dropdown);
// import Vue from 'vue';
// import VueSuggestion from 'vue-suggestion';
// import itemTemplate from "@/views/pages/products/ItemTemplate";
// Vue.use(VueSuggestion);
// Vue.use(Dropdown);
/**
 * Add Product Component
 */
export default {
  components: {
    // vueDropzone: vue2Dropzone,
    // VueUploadMultipleImage,
    Multiselect,
    Layout,
    PageHeader,
    // VueSimpleSuggest,
    // Dropdown,
    // Multiselect,
    ckeditor: CKEditor.component
  },
  data() {
    return {
      title: "PRODUCT EDIT",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "PRODUCT LIST",
          href: "/products"
        },
        {
          text: "PRODUCT EDIT",
          active: true
        }
      ],

      products:{
        product_type:"",
        productTypeID:"",
        type_id:"",
        product_title:"",
        brandId:"",
          preOrder:[],
        sku:[],
        images:[],
        cadBlock:[],
        cadBlockDwg:[],
        compare_price:[],
        cost_per_item:[],
        product_price:[],
        product_status:"1",
        sizeId:["",""],
        colorId:["",""],
        available_stock:[],
        dimension:[],
        specificationId:[],
        specificationValue:[],
        filterId:[],
        filterValue:[],
        files:[],
        cadFiles:[],
        cadDwgFiles:[],
        description:"",
        varients_data:[],
        seoTitle:"",
        seoDescription:"",
        seoKeywords:"",
        glbObject:"",
        glbZip:"",
        logistics_information:[],
        hsCode:[],
        variantId:[],
        countryOrigin:["","","",""],

      },
      listSubCategoryData: [],
      dynamicVariants:"",
      descriptionError:"",
      imagesError:"",
      varients:[],
      image_list:[],
      image_Dynamiclist:[],
      preview_list:[],
      preview_dynamic:[],
      searchOption:[],
      brandData:[],
      sizeData:[],
      colorData:[],
      specifications:[],
      filters:[],
      productData:[],
      dataSpecifications:[],
      value: null,
      value1: null,
      small: false,
      selected: {},
      searchFilter: "",
      selectedData: "",
      dynamicFormData: "",
      countryData:[],
      inputs: [
        {
          name: ''
        }
      ],
      inputsInfo: [
        {
          info: ''
        }
      ],
      submitted: false,
      editor: ClassicEditor,
      // dropzoneOptions: {
      //   url: "http://localhost:8000/api/admin/test",
      //   // url: "https://b2b-staging.lab2100.com/api/admin/addTempProductImages",
      //   thumbnailWidth: 150,
      //   paramName: 'image_name',
      //   params: {
      //     path: "jhsadhasbdmaskjkj/askjdaskd.png"
      //   },
      //   maxFilesize: 0.5,
      //   addRemoveLinks: true,
      //   uploadMultiple: true,
      //   autoProcessQueue: true,
      //   maxFiles: 3,
      //   parallelUploads: 3,
      //   init: function() {
      //     this.on("sendingFile", function(file, xhr, formData){
      //       formData.append("params");
      //     });
      //   },
      //   headers: { "My-Awesome-Header": "header value" }
      // }
    };
  },

  mounted() {
    Master.listSubCategoryList({
      trash: 0,
      subCategoryId: 0,
      status:1
    }).then((res) => {
      console.log(res)
      this.listSubCategoryData = res.data.data;

    });

    Master.countryList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.countryData = res.data.data;
    })
    Master.vendorList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.brandData = res.data.data;
    })
    // Master.listSubCategoryList({
    //   trash: 0,
    //   subCategoryId: 0
    // }).then((res) => {
    //   console.log(res)
    //   this.listSubCategoryData = res.data.data;
    //
    // });
    Master.productDetails({
      productId: localStorage.getItem('productId')
    }).then((res) => {
      console.log(res)
      this.productData = res.data.data;
      this.products.product_type = {id: this.productData.listSubCategoryId, name: this.productData.listSubCategory}
      this.products.brandId = res.data.data.brandId
      this.products.product_status  = res.data.data.status
      this.products.productTypeID = res.data.data.categoryId+","+res.data.data.subCategoryId+","+res.data.data.listSubCategoryId
      Master.filterSpecificationByListSubMenu(
          {
            "listSubCategoryId":res.data.data.listSubCategoryId
          }
      ).then((response) => {
        // Specification
        var specificationDetails = res.data.data.specifications
        var specificationBySubMenu = response.data.data.specifications
        var newArray = [];
        specificationBySubMenu.forEach(item => {
          var newItem = {specificationId: item.specificationId, specificationName:item.specificationName, specificationValue: "" };
          specificationDetails.forEach(innerItem => {
            if(innerItem.specificationId == item.specificationId){
              newItem.specificationValue = newItem.specificationValue.concat(innerItem.specificationValue);
            }
          });
          newArray.push(newItem);
        });
        this.specifications = newArray
        for (var i = 0; i < newArray.length; i++){
          this.products.specificationValue[i] = newArray[i].specificationValue
        }
        //
        // Filters
        var filterDetails = res.data.data.filters
        var filterBySubMenu = response.data.data.filters
        var newArray1 = [];
        filterBySubMenu.forEach(item1 => {
          var newItem1 = {filterId: item1.filterId, filterName:item1.filterName, filterValue: "" };
          filterDetails.forEach(innerItem1 => {
            if(innerItem1.filterId == item1.filterId){
              newItem1.filterValue = newItem1.filterValue.concat(innerItem1.filter_value);
            }
          });
          newArray1.push(newItem1);
        });
        this.filters = newArray1
        for (var j = 0; j < newArray1.length; j++){
          this.products.filterValue[j] = newArray1[j].filterValue
        }

      })
      this.products.description = res.data.data.productDescription
      this.products.product_title = res.data.data.title
      this.products.glbObject = res.data.data.glbObject
      this.products.sku[0] = res.data.data.variants[0].sku
      this.products.variantId[0] = res.data.data.variants[0].id
      this.products.product_price[0] = res.data.data.variants[0].price
      this.products.compare_price[0] = res.data.data.variants[0].compareAtPrice
      this.products.cost_per_item[0] = res.data.data.variants[0].costPerItem
      this.products.preOrder[0] = res.data.data.variants[0].preOrder

      this.products.sizeId[0] = res.data.data.variants[0].sizeId
      this.products.colorId[0] = res.data.data.variants[0].colorId
      this.products.available_stock[0] = res.data.data.variants[0].stock
      this.products.dimension[0] = res.data.data.variants[0].dimensions
      this.products.hsCode[0] = res.data.data.variants[0].hsCode
      this.products.logistics_information = res.data.data.logisticsInformations.split(',')
      if (res.data.data.logisticsInformations.split(',').length > 1){
        for (var o = 0; o < res.data.data.logisticsInformations.split(',').length; o++) {
          this.inputsInfo.push({info: ''})
        }
    }





      this.selected = {id:1, name:"DINING"}
      this.dynamicFormData = (res.data.data.variants.length-1)
      this.dynamicVariants = res.data.data.variants.length
      this.products.product_type = {id:res.data.data.listSubCategoryId, name:res.data.data.listSubCategory}
      for (var i = 1; i <= res.data.data.variants.length-1; i++){
        // console.log(res.data.data.variants.length-1);
        this.products.variantId[i] = res.data.data.variants[i].id
        // this.products.cadBlock[i] = res.data.data.variants[i].catBlock
        this.products.sku[i] = res.data.data.variants[i].sku
        this.products.sizeId[i] = res.data.data.variants[i].sizeId
        this.products.colorId[i] = res.data.data.variants[i].colorId
        this.products.available_stock[i] = res.data.data.variants[i].stock
        this.products.dimension[i] = res.data.data.variants[i].dimensions
        this.products.product_price[i] = res.data.data.variants[i].price
        this.products.compare_price[i] = res.data.data.variants[i].compareAtPrice
        this.products.cost_per_item[i] = res.data.data.variants[i].costPerItem
        this.products.hsCode[i] = res.data.data.variants[i].hsCode
      }






      Master.searchProductType({
        searchStr:res.data.data.listSubCategory
      }).then((res) => {
        console.log(res.data.data)
        this.searchOption = res.data.data
      })
      // this.specifications = res.data.data.specifications
      // for((speci,index) in res.data.data.specifications){
      //   this.products.specificationValue[index] = speci.specificationValue
      // }
      // this.filters = res.data.data.filters


      this.products.seoTitle = res.data.data.seoTitle
      this.products.seoDescription = res.data.data.seoDescription
      this.products.seoKeywords = res.data.data.seoKeywords
    })




    Master.sizeList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.sizeData = res.data.data;
    })
    Master.colorList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.colorData = res.data.data;
    })
  },
  validations: {
    products: {
      product_type: {
        required
      },
      product_title: { required  },

      sku: {required},


      available_stock: { required },

      product_price: { required },

      // cost_per_item: { decimal },
      // compare_price: { decimal },


      description: { required },
      brandId: { required }

    }
  },

  methods:{
    add() {
      this.inputs.push({ name: '' });
    },


    validateSelection(selection) {
      // this.products.product_type = selection

      this.selected = selection;

      let ListsubCatId = selection.id;
      Master.filterSpecificationByListSubMenu(
          {
            "listSubCategoryId":ListsubCatId
          }
      ).then((res) => {
        // console.log(res)
        this.specifications = res.data.data.specifications
        this.filters = res.data.data.filters
      })
      // console.log(selection.name + " has been selected");
    },
    selectedGlb(event){
      this.products.glbObject = event.target.files[0]
    },
    selectedZip(event){
      this.products.glbZip = event.target.files[0]
    },

    getDropdownValues(keyword) {
      this.specifications = ""
      this.filters = ""
      return Master.searchProductType({
        searchStr:keyword
      }).then((res) => {
        console.log(res.data.data)
        this.searchOption = res.data.data
      })
      // console.log("You could refresh options by querying the API with " + keyword);
    },
    remove(index) {
      this.inputs.splice(index, 1);

      this.products.sku.splice(index+1, 1)
      this.products.sizeId.splice(index+1, 1)
      this.products.colorId.splice(index+1, 1)
      this.products.product_price.splice(index+1, 1)
      this.products.compare_price.splice(index+1, 1)
      this.products.cost_per_item.splice(index+1, 1)
      this.products.available_stock.splice(index+1, 1)
      this.products.dimension.splice(index+1, 1)
      this.products.files.splice(index-1, 1)
      this.products.hsCode.splice(index-1, 1)
      // console.log(this.products.files.length)
      // this.preview_dynamic.splice(index+2,3)

    },
    // searchType(){
    //   this.specifications = ""
    //   this.filters = ""
    //   return Master.searchProductType({
    //     searchStr:this.products.product_type
    //   }).then((res) => {
    //     console.log(res.data.data)
    //     this.searchOption = res.data.data
    //   })
    // },

    onSuggestClick(event){
      this.products.productTypeID = event.categoryId+","+event.subCategoryIKd+","+event.id
      console.log(event.subCategoryIKd)
      let ListsubCatId = event.id;
      Master.filterSpecificationByListSubMenu(
          {
            "listSubCategoryId":ListsubCatId
          }
      ).then((res) => {
        console.log(res)
        this.specifications = res.data.data.specifications
        this.filters = res.data.data.filters
      })
    },

    executeDynamic (data) {
      var el = document.getElementById("fileId-"+data)
      // el.addEventListener("change", localStorage.setItem("file"+(data+1)+"[]", event.target.files), false);
      el.addEventListener("change", this.$data.products.files[(data-1)] = event.target.files, false);

      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while(count --) {
          var reader = new FileReader();
          reader.onload = (e) => {
            // console.log(this.preview_dynamic.length)
            this.preview_dynamic.push({"name":"IMAGE "+(data+1)+"","preview":e.target.result});
          }
          // this.image_Dynamiclist.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index ++;
        }
        // this.image_Dynamiclist.push({"name":"VARIENT "+(data+1)+""});
      }



    },
    executeCadDynamic (data) {
      var el = document.getElementById("catFileId-"+data)
      // el.addEventListener("change", localStorage.setItem("file"+(data+1)+"[]", event.target.files), false);
      el.addEventListener("change", this.$data.products.cadFiles[(data-1)] = event.target.files, false);
    },
    executeCadDwgDynamic (data) {
      var el = document.getElementById("catFileDwgId-"+data)
      el.addEventListener("change", this.$data.products.cadDwgFiles[(data-1)] = event.target.files, false);
    },






    onFileSelected(event){
      console.log(event.target.files)
      this.products.images = event.target.files
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while(count --) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_dynamic.push({"name":"IMAGE "+1+"","preview":e.target.result});
          }
          this.image_list.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index ++;
        }
      }
    },
    onCadFileSelected(event){
      console.log(event.target.files)
      this.products.cadBlock = event.target.files[0]
    },
    onCadFileDwgSelected(event){
      console.log(event.target.files)
      this.products.cadBlockDwg  = event.target.files[0]
    },


    uploadImageSuccess(formData, index, fileList) {
      console.log('data', formData, index, fileList)
    },
    beforeRemove (index, done, fileList) {
      console.log('index', index, fileList)
      var r = confirm("remove image")
      if (r == true) {
        done()
      } else {
        console.log("image not removed")
      }
    },
    editImage (formData, index, fileList) {
      console.log('edit data', formData, index, fileList)
    },

    deleteImage(index){
      this.preview_dynamic.splice(index, 1)
    },
    handleSubmit(){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.products.$invalid) {
        return;
      } else {
        this.descriptionError = ""
        let sku = this.products.sku
        // let cadBlock = this.products.cadBlock
        let stock = this.products.available_stock
        let size = this.products.sizeId
        let color = this.products.colorId
        let price = this.products.product_price
        let compareAtPrice = this.products.compare_price
        let costPerItem = this.products.cost_per_item
        let dimension = this.products.dimension
        let hsCode = this.products.hsCode
        let variantId = this.products.variantId
        let preOrder = this.products.preOrder



        let finalArray = [];

        sku.forEach((value,index) => {
          let obj = {};
          obj.sku = value;
          obj.variantId = variantId[index] ? variantId[index] : "";
          obj.stock = stock[index] ? stock[index] : "";
          obj.size = size[index] ? size[index] : "";
          // obj.cadBlock = cadBlock[index] ? cadBlock[index] : "";
          obj.color = color[index] ? color[index] : "";
          obj.price = price[index] ? price[index] : "";
          obj.compareAtPrice = compareAtPrice[index] ? compareAtPrice[index] : "";
          obj.costPerItem = costPerItem[index] ? costPerItem[index] : "";
          obj.dimensions = dimension[index] ? dimension[index] : "";
          obj.hsCode = hsCode[index] ? hsCode[index] : "";
          obj.preOrder = preOrder[index] ? preOrder[index] : 0;
          finalArray.push(obj);
        })

        console.log(finalArray)


        var specificationValue = this.products.specificationValue
        var specificationId = this.products.specificationId
        var resultMergedSpecification = [];
        for (var i=0;i<specificationValue.length;++i) {
          if (specificationValue[i] != "") {
            resultMergedSpecification = specificationValue.map((idx, value) => {
              return {"specificationId":specificationId[value], "specificationValue":idx }
            })

          }
        }
        resultMergedSpecification = resultMergedSpecification.filter(function( element ) {
          return element !== undefined;
        });
        console.log(resultMergedSpecification)



        let filterValueArray = this.products.filterValue
        let filterIdArray = this.products.filterId
        let mergedFilter = [];
        for (var k=0;k<filterValueArray.length;++k) {
          if (filterValueArray[k] != "") {
            mergedFilter = filterValueArray.map((idf, valuef) => {
              return { "filterId":filterIdArray[valuef] , "filterValue":idf }
            })

          }
        }
        mergedFilter = mergedFilter.filter(function( element ) {
          return element !== undefined;
        });
        console.log(mergedFilter)

        const fd = new FormData();
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        // fd.append("glbObject",this.products.glbObject);
        fd.append("glbZip",this.products.glbZip);
        fd.append("catBlock1",this.products.cadBlock);
        fd.append("catBlockDwg1",this.products.cadBlockDwg);
        for (let i =0; i<this.products.images.length ; i++){
          var obj = this.products.images[i];
          fd.append("file1[]",obj);
        }
        // for (var j =2; j <= this.products.varients_data ; j++){
        // alert("file"+j+"[]")
        for (let i =0; i < this.products.files.length ; i++){
          var obj1 = this.products.files[i];
          for (let k = 0; k < obj1.length; k++){
            var obj2 = obj1[k];
            fd.append("file"+(i+2)+"[]",obj2);
          }

        }
        for (let c =0; c < this.products.cadFiles.length ; c++){
          var cadobj = this.products.cadFiles[c];
          for (let ca = 0; ca < cadobj.length; ca++){
            var catobj2 = cadobj[ca];
            fd.append("catBlock"+(c+2),catobj2);
          }
        }
        for (let d =0; d < this.products.cadDwgFiles.length ; d++){
          var cadDwgobj = this.products.cadDwgFiles[d];
          for (let dw = 0; dw < cadDwgobj.length; dw++){
            var catDwgobj2 = cadDwgobj[dw];
            fd.append("catBlockDwg"+(d+2),catDwgobj2);
          }
        }
        // }

        fd.append('data',JSON.stringify(
            {
              "productId":localStorage.getItem('productId'),
              "updatedBy":1,
              "category":this.products.productTypeID,
              "glbObject":this.products.glbObject,
              "vendorId":this.products.brandId,
              "title": this.products.product_title,
              "productDescription":this.products.description,
              "source":"B2B",

              "status":this.products.product_status,
              "seoTitle":this.products.seoTitle,
              "seoDescription":this.products.seoDescription,
              "seoKeywords":this.products.seoKeywords,
              "variants":finalArray,
              "productSpecifications":resultMergedSpecification,
              "logistics":this.products.logistics_information.join(", "),
              "productFilters":mergedFilter
            }
        ));
        Master.productUpdate(fd,config).then((res) => {
        // axios.post('https://b2b-staging.lab2100.com/api/admin/updateProduct', fd,config).then((res) => {
          console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 2000
          });
          this.$router.push({path: '/products'})
        }).catch((err) => {
          console.log(err.response.data)
          this.$bvToast.toast(""+err.response.data.error.title[0]+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
        })

      }
    },
    sendingFile(file) {

      this.products.files.push(file.upload.filename);

      // const fd = new FormData();
      // fd.append('image_name[]', file);
      //
      // axios.post('http://localhost:8000/api/admin/test', fd)
      // // Master.addTempProductImage(fd)
      //     .then((res) => {
      //       console.log(res.data.message)
      //     }).catch((err) => {
      //   console.log(err)
      //
      // })
    },
    sendingFile1(file){
      console.log(file)
    },
    addField() {
      this.inputsInfo.push({ info: '' });
    },
    removeField(index, val) {
      if (val != null){
        if(confirm("DO YOU REALLY WANT TO UPDATE LOGISTICS INFORMATION DATA?")) {
          if (this.products.logistics_information[index] == val) {
            this.products.logistics_information.splice(index, 1)
          }
        }
      }
      this.inputsInfo.splice(index, 1);


    },
    removeInfo() {
      alert()
    },
     removeData(data,index) {
      // alert(index+1)
       if(confirm("Do you really want to delete?")) {
         Master.DeleteVariant({
           id: data
         }).then((res1) => {
           console.log(res1)
           Master.productDetails({
             productId: localStorage.getItem('productId')
           }).then((res) => {
             console.log(res)



             this.products.sku.splice(index+1, 1)
             this.products.sizeId.splice(index+1, 1)
             this.products.colorId.splice(index+1, 1)
             this.products.product_price.splice(index+1, 1)
             this.products.compare_price.splice(index+1, 1)
             this.products.cost_per_item.splice(index+1, 1)
             this.products.available_stock.splice(index+1, 1)
             this.products.dimension.splice(index+1, 1)
             this.products.files.splice(index-1, 1)
             this.products.hsCode.splice(index-1, 1)


             this.productData = res.data.data;
             this.products.description = res.data.data.productDescription
             this.products.product_title = res.data.data.title
             this.products.sku[0] = res.data.data.variants[0].sku
             this.products.product_price[0] = res.data.data.variants[0].price
             this.products.compare_price[0] = res.data.data.variants[0].compareAtPrice
             this.products.cost_per_item[0] = res.data.data.variants[0].costPerItem
             this.products.preOrder[0] = res.data.data.variants[0].preOrder
             this.products.status = res.data.data.product_status
             this.products.sizeId[0] = res.data.data.variants[0].sizeId
             this.products.colorId[0] = res.data.data.variants[0].colorId
             this.products.available_stock[0] = res.data.data.variants[0].stock
             this.products.dimension[0] = res.data.data.variants[0].dimensions
             this.products.hsCode[0] = res.data.data.variants[0].hsCode
             this.products.brandId = res.data.data.brandId
             this.products.logistics_information = res.data.data.logisticsInformations.split(',')
             // for (var o = 0; o < res.data.data.logisticsInformations.split(',').length; o++) {
             //   this.inputsInfo.push({info: ''})
             // }


             this.products.productTypeID = res.data.data.categoryId + "," + res.data.data.subCategoryId + "," + res.data.data.listSubCategoryId

             this.selected = {id: 1, name: "DINING"}
             this.dynamicFormData = (res.data.data.variants.length - 1)
             this.dynamicVariants = res.data.data.variants.length
             this.products.product_type = {id: res.data.data.listSubCategoryId, name: res.data.data.listSubCategory}

             for (var i = 1; i <= res.data.data.variants.length - 1; i++) {
               this.products.sku[i] = res.data.data.variants[i].sku
               this.products.sizeId[i] = res.data.data.variants[i].sizeId
               this.products.colorId[i] = res.data.data.variants[i].colorId
               this.products.available_stock[i] = res.data.data.variants[i].stock
               this.products.dimension[i] = res.data.data.variants[i].dimensions
               this.products.product_price[i] = res.data.data.variants[i].price
               this.products.compare_price[i] = res.data.data.variants[i].compareAtPrice
               this.products.cost_per_item[i] = res.data.data.variants[i].costPerItem
               this.products.hsCode[i] = res.data.data.variants[i].hsCode

             }

             Master.filterSpecificationByListSubMenu(
                 {
                   "listSubCategoryId": res.data.data.listSubCategoryId
                 }
             ).then((response) => {

               // Specification
               var specificationDetails = res.data.data.specifications
               var specificationBySubMenu = response.data.data.specifications
               var newArray = [];
               specificationBySubMenu.forEach(item => {
                 var newItem = {
                   specificationId: item.specificationId,
                   specificationName: item.specificationName,
                   specificationValue: ""
                 };
                 specificationDetails.forEach(innerItem => {
                   if (innerItem.specificationId == item.specificationId) {
                     newItem.specificationValue = newItem.specificationValue.concat(innerItem.specificationValue);
                   }
                 });
                 newArray.push(newItem);
               });
               this.specifications = newArray
               for (var i = 0; i < newArray.length; i++) {
                 this.products.specificationValue[i] = newArray[i].specificationValue
               }
               //
               // Filters
               var filterDetails = res.data.data.filters
               var filterBySubMenu = response.data.data.filters


               var newArray1 = [];
               filterBySubMenu.forEach(item1 => {
                 var newItem1 = {filterId: item1.filterId, filterName: item1.filterName, filterValue: ""};
                 filterDetails.forEach(innerItem1 => {
                   if (innerItem1.filterId == item1.filterId) {
                     newItem1.filterValue = newItem1.filterValue.concat(innerItem1.filter_value);
                   }
                 });
                 newArray1.push(newItem1);
               });
               this.filters = newArray1
               for (var j = 0; j < newArray1.length; j++) {
                 this.products.filterValue[j] = newArray1[j].filterValue
               }

             })


             Master.searchProductType({
               searchStr: res.data.data.listSubCategory
             }).then((res) => {
               console.log(res.data.data)
               this.searchOption = res.data.data
             })
             // this.specifications = res.data.data.specifications
             // for((speci,index) in res.data.data.specifications){
             //   this.products.specificationValue[index] = speci.specificationValue
             // }
             // this.filters = res.data.data.filters


             this.products.seoTitle = res.data.data.seoTitle
             this.products.seoDescription = res.data.data.seoDescription
             this.products.seoKeywords = res.data.data.seoKeywords
           })
         })
       }
    },
    deleteImageVarient1(data){

      if(confirm("Do you really want to delete variant image?")) {
        Master.DeleteVariantImage({
          id: data.id
        }).then((res1) => {
          console.log(res1)

          Master.productDetails({
            productId: localStorage.getItem('productId')
          }).then((res) => {
            console.log(res)


            this.productData = res.data.data;
            this.products.description = res.data.data.productDescription
            this.products.product_title = res.data.data.title
            this.products.sku[0] = res.data.data.variants[0].sku
            this.products.product_price[0] = res.data.data.variants[0].price
            this.products.compare_price[0] = res.data.data.variants[0].compareAtPrice
            this.products.cost_per_item[0] = res.data.data.variants[0].costPerItem
            this.products.preOrder[0] = res.data.data.variants[0].preOrder
            this.products.status = res.data.data.product_status
            this.products.sizeId[0] = res.data.data.variants[0].sizeId
            this.products.colorId[0] = res.data.data.variants[0].colorId
            this.products.available_stock[0] = res.data.data.variants[0].stock
            this.products.dimension[0] = res.data.data.variants[0].dimensions
            this.products.hsCode[0] = res.data.data.variants[0].hsCode
            this.products.brandId = res.data.data.brandId
            this.products.logistics_information = res.data.data.logisticsInformations.split(',')
            // for (var o = 0; o < res.data.data.logisticsInformations.split(',').length; o++) {
            //   this.inputsInfo.push({info: ''})
            // }


            this.products.productTypeID = res.data.data.categoryId + "," + res.data.data.subCategoryId + "," + res.data.data.listSubCategoryId

            this.selected = {id: 1, name: "DINING"}
            this.dynamicFormData = (res.data.data.variants.length - 1)
            this.dynamicVariants = res.data.data.variants.length
            this.products.product_type = {id: res.data.data.listSubCategoryId, name: res.data.data.listSubCategory}

            for (var i = 1; i <= res.data.data.variants.length - 1; i++) {
              this.products.sku[i] = res.data.data.variants[i].sku
              this.products.sizeId[i] = res.data.data.variants[i].sizeId
              this.products.colorId[i] = res.data.data.variants[i].colorId
              this.products.available_stock[i] = res.data.data.variants[i].stock
              this.products.dimension[i] = res.data.data.variants[i].dimensions
              this.products.product_price[i] = res.data.data.variants[i].price
              this.products.compare_price[i] = res.data.data.variants[i].compareAtPrice
              this.products.cost_per_item[i] = res.data.data.variants[i].costPerItem
              this.products.hsCode[i] = res.data.data.variants[i].hsCode

            }

            Master.filterSpecificationByListSubMenu(
                {
                  "listSubCategoryId": res.data.data.listSubCategoryId
                }
            ).then((response) => {

              // Specification
              var specificationDetails = res.data.data.specifications
              var specificationBySubMenu = response.data.data.specifications
              var newArray = [];
              specificationBySubMenu.forEach(item => {
                var newItem = {
                  specificationId: item.specificationId,
                  specificationName: item.specificationName,
                  specificationValue: ""
                };
                specificationDetails.forEach(innerItem => {
                  if (innerItem.specificationId == item.specificationId) {
                    newItem.specificationValue = newItem.specificationValue.concat(innerItem.specificationValue);
                  }
                });
                newArray.push(newItem);
              });
              this.specifications = newArray
              for (var i = 0; i < newArray.length; i++) {
                this.products.specificationValue[i] = newArray[i].specificationValue
              }
              //
              // Filters
              var filterDetails = res.data.data.filters
              var filterBySubMenu = response.data.data.filters


              var newArray1 = [];
              filterBySubMenu.forEach(item1 => {
                var newItem1 = {filterId: item1.filterId, filterName: item1.filterName, filterValue: ""};
                filterDetails.forEach(innerItem1 => {
                  if (innerItem1.filterId == item1.filterId) {
                    newItem1.filterValue = newItem1.filterValue.concat(innerItem1.filter_value);
                  }
                });
                newArray1.push(newItem1);
              });
              this.filters = newArray1
              for (var j = 0; j < newArray1.length; j++) {
                this.products.filterValue[j] = newArray1[j].filterValue
              }

            })


            Master.searchProductType({
              searchStr: res.data.data.listSubCategory
            }).then((res) => {
              console.log(res.data.data)
              this.searchOption = res.data.data
            })
            // this.specifications = res.data.data.specifications
            // for((speci,index) in res.data.data.specifications){
            //   this.products.specificationValue[index] = speci.specificationValue
            // }
            // this.filters = res.data.data.filters


            this.products.seoTitle = res.data.data.seoTitle
            this.products.seoDescription = res.data.data.seoDescription
            this.products.seoKeywords = res.data.data.seoKeywords
          })




        })
      }
    },
    deleteCadBlock(data){
      if(confirm("Do you really want to delete variant image?")) {
        Master.DeleteCadBlock({
          variantId: data,
          userId:1

        }).then((res1) => {
          console.log(res1)

          Master.productDetails({
            productId: localStorage.getItem('productId')
          }).then((res) => {
            console.log(res)


            this.productData = res.data.data;
          //   this.products.description = res.data.data.productDescription
          //   this.products.product_title = res.data.data.title
          //   this.products.sku[0] = res.data.data.variants[0].sku
          //   this.products.product_price[0] = res.data.data.variants[0].price
          //   this.products.compare_price[0] = res.data.data.variants[0].compareAtPrice
          //   this.products.cost_per_item[0] = res.data.data.variants[0].costPerItem
          //   this.products.preOrder = res.data.data.preOrder
          //   this.products.status = res.data.data.product_status
          //   this.products.sizeId[0] = res.data.data.variants[0].sizeId
          //   this.products.colorId[0] = res.data.data.variants[0].colorId
          //   this.products.available_stock[0] = res.data.data.variants[0].stock
          //   this.products.dimension[0] = res.data.data.variants[0].dimensions
          //   this.products.hsCode[0] = res.data.data.variants[0].hsCode
          //   this.products.brandId = res.data.data.brandId
          //   this.products.logistics_information = res.data.data.logisticsInformations.split(',')
          //   // for (var o = 0; o < res.data.data.logisticsInformations.split(',').length; o++) {
          //   //   this.inputsInfo.push({info: ''})
          //   // }
          //
          //
          //   this.products.productTypeID = res.data.data.categoryId + "," + res.data.data.subCategoryId + "," + res.data.data.listSubCategoryId
          //
          //   this.selected = {id: 1, name: "DINING"}
          //   this.dynamicFormData = (res.data.data.variants.length - 1)
          //   this.dynamicVariants = res.data.data.variants.length
          //   this.products.product_type = {id: res.data.data.listSubCategoryId, name: res.data.data.listSubCategory}
          //
          //   for (var i = 1; i <= res.data.data.variants.length - 1; i++) {
          //     this.products.sku[i] = res.data.data.variants[i].sku
          //     this.products.sizeId[i] = res.data.data.variants[i].sizeId
          //     this.products.colorId[i] = res.data.data.variants[i].colorId
          //     this.products.available_stock[i] = res.data.data.variants[i].stock
          //     this.products.dimension[i] = res.data.data.variants[i].dimensions
          //     this.products.product_price[i] = res.data.data.variants[i].price
          //     this.products.compare_price[i] = res.data.data.variants[i].compareAtPrice
          //     this.products.cost_per_item[i] = res.data.data.variants[i].costPerItem
          //     this.products.hsCode[i] = res.data.data.variants[i].hsCode
          //
          //   }
          //
          //   Master.filterSpecificationByListSubMenu(
          //           {
          //             "listSubCategoryId": res.data.data.listSubCategoryId
          //           }
          //   ).then((response) => {
          //
          //     // Specification
          //     var specificationDetails = res.data.data.specifications
          //     var specificationBySubMenu = response.data.data.specifications
          //     var newArray = [];
          //     specificationBySubMenu.forEach(item => {
          //       var newItem = {
          //         specificationId: item.specificationId,
          //         specificationName: item.specificationName,
          //         specificationValue: ""
          //       };
          //       specificationDetails.forEach(innerItem => {
          //         if (innerItem.specificationId == item.specificationId) {
          //           newItem.specificationValue = newItem.specificationValue.concat(innerItem.specificationValue);
          //         }
          //       });
          //       newArray.push(newItem);
          //     });
          //     this.specifications = newArray
          //     for (var i = 0; i < newArray.length; i++) {
          //       this.products.specificationValue[i] = newArray[i].specificationValue
          //     }
          //     //
          //     // Filters
          //     var filterDetails = res.data.data.filters
          //     var filterBySubMenu = response.data.data.filters
          //
          //
          //     var newArray1 = [];
          //     filterBySubMenu.forEach(item1 => {
          //       var newItem1 = {filterId: item1.filterId, filterName: item1.filterName, filterValue: ""};
          //       filterDetails.forEach(innerItem1 => {
          //         if (innerItem1.filterId == item1.filterId) {
          //           newItem1.filterValue = newItem1.filterValue.concat(innerItem1.filter_value);
          //         }
          //       });
          //       newArray1.push(newItem1);
          //     });
          //     this.filters = newArray1
          //     for (var j = 0; j < newArray1.length; j++) {
          //       this.products.filterValue[j] = newArray1[j].filterValue
          //     }
          //
          //   })
          //
          //
          //   Master.searchProductType({
          //     searchStr: res.data.data.listSubCategory
          //   }).then((res) => {
          //     console.log(res.data.data)
          //     this.searchOption = res.data.data
          //   })
          //   // this.specifications = res.data.data.specifications
          //   // for((speci,index) in res.data.data.specifications){
          //   //   this.products.specificationValue[index] = speci.specificationValue
          //   // }
          //   // this.filters = res.data.data.filters
          //
          //
          //   this.products.seoTitle = res.data.data.seoTitle
          //   this.products.seoDescription = res.data.data.seoDescription
          //   this.products.seoKeywords = res.data.data.seoKeywords
          })

          })
      }
    },
    deleteZip(){
      if(confirm("Do you really want to delete GLB Zip File?")) {
        // productZipDelete
        let userData = JSON.parse(localStorage.getItem("user"))
        Master.productZipDelete({
          productId:localStorage.getItem('productId'),
          userId:userData.id
        }).then((res1) => {
          console.log(res1)

          Master.productDetails({
            productId: localStorage.getItem('productId')
          }).then((res) => {
            console.log(res)


            this.productData = res.data.data;
            this.products.description = res.data.data.productDescription
            this.products.product_title = res.data.data.title
            this.products.sku[0] = res.data.data.variants[0].sku
            this.products.product_price[0] = res.data.data.variants[0].price
            this.products.compare_price[0] = res.data.data.variants[0].compareAtPrice
            this.products.cost_per_item[0] = res.data.data.variants[0].costPerItem
            this.products.preOrder[0] = res.data.data.variants[0].preOrder
            this.products.status = res.data.data.product_status
            this.products.sizeId[0] = res.data.data.variants[0].sizeId
            this.products.colorId[0] = res.data.data.variants[0].colorId
            this.products.available_stock[0] = res.data.data.variants[0].stock
            this.products.dimension[0] = res.data.data.variants[0].dimensions
            this.products.hsCode[0] = res.data.data.variants[0].hsCode
            this.products.brandId = res.data.data.brandId
            this.products.logistics_information = res.data.data.logisticsInformations.split(',')

            // for (var o = 0; o < res.data.data.logisticsInformations.split(',').length; o++) {
            //   this.inputsInfo.push({info: ''})
            // }


            this.products.productTypeID = res.data.data.categoryId + "," + res.data.data.subCategoryId + "," + res.data.data.listSubCategoryId

            this.selected = {id: 1, name: "DINING"}
            this.dynamicFormData = (res.data.data.variants.length - 1)
            this.dynamicVariants = res.data.data.variants.length
            this.products.product_type = {id: res.data.data.listSubCategoryId, name: res.data.data.listSubCategory}

            for (var i = 1; i <= res.data.data.variants.length - 1; i++) {
              this.products.sku[i] = res.data.data.variants[i].sku
              this.products.sizeId[i] = res.data.data.variants[i].sizeId
              this.products.colorId[i] = res.data.data.variants[i].colorId
              this.products.available_stock[i] = res.data.data.variants[i].stock
              this.products.dimension[i] = res.data.data.variants[i].dimensions
              this.products.product_price[i] = res.data.data.variants[i].price
              this.products.compare_price[i] = res.data.data.variants[i].compareAtPrice
              this.products.cost_per_item[i] = res.data.data.variants[i].costPerItem
              this.products.hsCode[i] = res.data.data.variants[i].hsCode

            }

            Master.filterSpecificationByListSubMenu(
                {
                  "listSubCategoryId": res.data.data.listSubCategoryId
                }
            ).then((response) => {

              // Specification
              var specificationDetails = res.data.data.specifications
              var specificationBySubMenu = response.data.data.specifications
              var newArray = [];
              specificationBySubMenu.forEach(item => {
                var newItem = {
                  specificationId: item.specificationId,
                  specificationName: item.specificationName,
                  specificationValue: ""
                };
                specificationDetails.forEach(innerItem => {
                  if (innerItem.specificationId == item.specificationId) {
                    newItem.specificationValue = newItem.specificationValue.concat(innerItem.specificationValue);
                  }
                });
                newArray.push(newItem);
              });
              this.specifications = newArray
              for (var i = 0; i < newArray.length; i++) {
                this.products.specificationValue[i] = newArray[i].specificationValue
              }
              //
              // Filters
              var filterDetails = res.data.data.filters
              var filterBySubMenu = response.data.data.filters


              var newArray1 = [];
              filterBySubMenu.forEach(item1 => {
                var newItem1 = {filterId: item1.filterId, filterName: item1.filterName, filterValue: ""};
                filterDetails.forEach(innerItem1 => {
                  if (innerItem1.filterId == item1.filterId) {
                    newItem1.filterValue = newItem1.filterValue.concat(innerItem1.filter_value);
                  }
                });
                newArray1.push(newItem1);
              });
              this.filters = newArray1
              for (var j = 0; j < newArray1.length; j++) {
                this.products.filterValue[j] = newArray1[j].filterValue
              }

            })


            Master.searchProductType({
              searchStr: res.data.data.listSubCategory
            }).then((res) => {
              console.log(res.data.data)
              this.searchOption = res.data.data
            })
            // this.specifications = res.data.data.specifications
            // for((speci,index) in res.data.data.specifications){
            //   this.products.specificationValue[index] = speci.specificationValue
            // }
            // this.filters = res.data.data.filters


            this.products.seoTitle = res.data.data.seoTitle
            this.products.seoDescription = res.data.data.seoDescription
            this.products.seoKeywords = res.data.data.seoKeywords
          })


        })
      }
    },
    deleteCadBlockDwg(data){
      if(confirm("Do you really want to delete Cad Block File?")) {
        // productZipDelete
        let userData = JSON.parse(localStorage.getItem("user"))
        Master.cadBlockDwgDelete({
          variantId:data,
          userId:userData.id
        }).then((res1) => {
          console.log(res1)

          Master.productDetails({
            productId: localStorage.getItem('productId')
          }).then((res) => {
            console.log(res)


            this.productData = res.data.data;
            this.products.description = res.data.data.productDescription
            this.products.product_title = res.data.data.title
            this.products.sku[0] = res.data.data.variants[0].sku
            this.products.product_price[0] = res.data.data.variants[0].price
            this.products.compare_price[0] = res.data.data.variants[0].compareAtPrice
            this.products.cost_per_item[0] = res.data.data.variants[0].costPerItem
            this.products.preOrder[0] = res.data.data.variants[0].preOrder
            this.products.status = res.data.data.product_status
            this.products.sizeId[0] = res.data.data.variants[0].sizeId
            this.products.colorId[0] = res.data.data.variants[0].colorId
            this.products.available_stock[0] = res.data.data.variants[0].stock
            this.products.dimension[0] = res.data.data.variants[0].dimensions
            this.products.hsCode[0] = res.data.data.variants[0].hsCode
            this.products.brandId = res.data.data.brandId
            this.products.logistics_information = res.data.data.logisticsInformations.split(',')

            // for (var o = 0; o < res.data.data.logisticsInformations.split(',').length; o++) {
            //   this.inputsInfo.push({info: ''})
            // }


            this.products.productTypeID = res.data.data.categoryId + "," + res.data.data.subCategoryId + "," + res.data.data.listSubCategoryId

            this.selected = {id: 1, name: "DINING"}
            this.dynamicFormData = (res.data.data.variants.length - 1)
            this.dynamicVariants = res.data.data.variants.length
            this.products.product_type = {id: res.data.data.listSubCategoryId, name: res.data.data.listSubCategory}

            for (var i = 1; i <= res.data.data.variants.length - 1; i++) {
              this.products.sku[i] = res.data.data.variants[i].sku
              this.products.sizeId[i] = res.data.data.variants[i].sizeId
              this.products.colorId[i] = res.data.data.variants[i].colorId
              this.products.available_stock[i] = res.data.data.variants[i].stock
              this.products.dimension[i] = res.data.data.variants[i].dimensions
              this.products.product_price[i] = res.data.data.variants[i].price
              this.products.compare_price[i] = res.data.data.variants[i].compareAtPrice
              this.products.cost_per_item[i] = res.data.data.variants[i].costPerItem
              this.products.hsCode[i] = res.data.data.variants[i].hsCode

            }

            Master.filterSpecificationByListSubMenu(
                {
                  "listSubCategoryId": res.data.data.listSubCategoryId
                }
            ).then((response) => {

              // Specification
              var specificationDetails = res.data.data.specifications
              var specificationBySubMenu = response.data.data.specifications
              var newArray = [];
              specificationBySubMenu.forEach(item => {
                var newItem = {
                  specificationId: item.specificationId,
                  specificationName: item.specificationName,
                  specificationValue: ""
                };
                specificationDetails.forEach(innerItem => {
                  if (innerItem.specificationId == item.specificationId) {
                    newItem.specificationValue = newItem.specificationValue.concat(innerItem.specificationValue);
                  }
                });
                newArray.push(newItem);
              });
              this.specifications = newArray
              for (var i = 0; i < newArray.length; i++) {
                this.products.specificationValue[i] = newArray[i].specificationValue
              }
              //
              // Filters
              var filterDetails = res.data.data.filters
              var filterBySubMenu = response.data.data.filters


              var newArray1 = [];
              filterBySubMenu.forEach(item1 => {
                var newItem1 = {filterId: item1.filterId, filterName: item1.filterName, filterValue: ""};
                filterDetails.forEach(innerItem1 => {
                  if (innerItem1.filterId == item1.filterId) {
                    newItem1.filterValue = newItem1.filterValue.concat(innerItem1.filter_value);
                  }
                });
                newArray1.push(newItem1);
              });
              this.filters = newArray1
              for (var j = 0; j < newArray1.length; j++) {
                this.products.filterValue[j] = newArray1[j].filterValue
              }

            })


            Master.searchProductType({
              searchStr: res.data.data.listSubCategory
            }).then((res) => {
              console.log(res.data.data)
              this.searchOption = res.data.data
            })
            // this.specifications = res.data.data.specifications
            // for((speci,index) in res.data.data.specifications){
            //   this.products.specificationValue[index] = speci.specificationValue
            // }
            // this.filters = res.data.data.filters


            this.products.seoTitle = res.data.data.seoTitle
            this.products.seoDescription = res.data.data.seoDescription
            this.products.seoKeywords = res.data.data.seoKeywords
          })


        })
      }
    },
    deleteImageVarients(data){
      if(confirm("Do you really want to delete variant image?")) {
        Master.DeleteVariantImage({
          id: data.id
        }).then((res1) => {
          console.log(res1)

          Master.productDetails({
            productId: localStorage.getItem('productId')
          }).then((res) => {
            console.log(res)


            this.productData = res.data.data;
            this.products.description = res.data.data.productDescription
            this.products.product_title = res.data.data.title
            this.products.sku[0] = res.data.data.variants[0].sku
            this.products.product_price[0] = res.data.data.variants[0].price
            this.products.compare_price[0] = res.data.data.variants[0].compareAtPrice
            this.products.cost_per_item[0] = res.data.data.variants[0].costPerItem
            this.products.preOrder[0] = res.data.data.variants[0].preOrder
            this.products.status = res.data.data.product_status
            this.products.sizeId[0] = res.data.data.variants[0].sizeId
            this.products.colorId[0] = res.data.data.variants[0].colorId
            this.products.available_stock[0] = res.data.data.variants[0].stock
            this.products.dimension[0] = res.data.data.variants[0].dimensions
            this.products.hsCode[0] = res.data.data.variants[0].hsCode
            this.products.brandId = res.data.data.brandId
            this.products.logistics_information = res.data.data.logisticsInformations.split(',')
            // for (var o = 0; o < res.data.data.logisticsInformations.split(',').length; o++) {
            //   this.inputsInfo.push({info: ''})
            // }


            this.products.productTypeID = res.data.data.categoryId + "," + res.data.data.subCategoryId + "," + res.data.data.listSubCategoryId

            this.selected = {id: 1, name: "DINING"}
            this.dynamicFormData = (res.data.data.variants.length - 1)
            this.dynamicVariants = res.data.data.variants.length
            this.products.product_type = {id: res.data.data.listSubCategoryId, name: res.data.data.listSubCategory}

            for (var i = 1; i <= res.data.data.variants.length - 1; i++) {
              this.products.sku[i] = res.data.data.variants[i].sku
              this.products.sizeId[i] = res.data.data.variants[i].sizeId
              this.products.colorId[i] = res.data.data.variants[i].colorId
              this.products.available_stock[i] = res.data.data.variants[i].stock
              this.products.dimension[i] = res.data.data.variants[i].dimensions
              this.products.product_price[i] = res.data.data.variants[i].price
              this.products.compare_price[i] = res.data.data.variants[i].compareAtPrice
              this.products.cost_per_item[i] = res.data.data.variants[i].costPerItem
              this.products.hsCode[i] = res.data.data.variants[i].hsCode

            }

            Master.filterSpecificationByListSubMenu(
                {
                  "listSubCategoryId": res.data.data.listSubCategoryId
                }
            ).then((response) => {

              // Specification
              var specificationDetails = res.data.data.specifications
              var specificationBySubMenu = response.data.data.specifications
              var newArray = [];
              specificationBySubMenu.forEach(item => {
                var newItem = {
                  specificationId: item.specificationId,
                  specificationName: item.specificationName,
                  specificationValue: ""
                };
                specificationDetails.forEach(innerItem => {
                  if (innerItem.specificationId == item.specificationId) {
                    newItem.specificationValue = newItem.specificationValue.concat(innerItem.specificationValue);
                  }
                });
                newArray.push(newItem);
              });
              this.specifications = newArray
              for (var i = 0; i < newArray.length; i++) {
                this.products.specificationValue[i] = newArray[i].specificationValue
              }
              //
              // Filters
              var filterDetails = res.data.data.filters
              var filterBySubMenu = response.data.data.filters


              var newArray1 = [];
              filterBySubMenu.forEach(item1 => {
                var newItem1 = {filterId: item1.filterId, filterName: item1.filterName, filterValue: ""};
                filterDetails.forEach(innerItem1 => {
                  if (innerItem1.filterId == item1.filterId) {
                    newItem1.filterValue = newItem1.filterValue.concat(innerItem1.filter_value);
                  }
                });
                newArray1.push(newItem1);
              });
              this.filters = newArray1
              for (var j = 0; j < newArray1.length; j++) {
                this.products.filterValue[j] = newArray1[j].filterValue
              }

            })


            Master.searchProductType({
              searchStr: res.data.data.listSubCategory
            }).then((res) => {
              console.log(res.data.data)
              this.searchOption = res.data.data
            })
            // this.specifications = res.data.data.specifications
            // for((speci,index) in res.data.data.specifications){
            //   this.products.specificationValue[index] = speci.specificationValue
            // }
            // this.filters = res.data.data.filters


            this.products.seoTitle = res.data.data.seoTitle
            this.products.seoDescription = res.data.data.seoDescription
            this.products.seoKeywords = res.data.data.seoKeywords
          })


        })
      }
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!--            <form v-on:submit.prevent="handleSubmit" enctype="multipart/form-data">-->
            <div class="tab-pane" id="basic-info">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="product_title">PRODUCT TITLE *</label>
                    <input id="product_title" v-model="products.product_title" type="text" class="form-control"
                           :class="{ 'is-invalid': submitted && $v.products.product_title.$error }"
                           placeholder="PRODUCT TITLE"
                           style="text-transform: uppercase !important;"
                    />
                    <div
                        v-if="submitted && !$v.products.product_title.required"
                        class="invalid-feedback"
                    >PRODUCT TITLE IS REQUIRED
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>PRODUCT TYPE *</label>
                    <!--                      <vue-simple-suggest-->
                    <!--                          id="suggest"-->
                    <!--                          v-model="products.product_type"-->
                    <!--                          :list="this.searchOption"-->
                    <!--                          :filter-by-query="true"-->
                    <!--                          @input="searchType"-->
                    <!--                          display-attribute="name"-->
                    <!--                          value-attribute="categoryId"-->
                    <!--                          @suggestion-click="onSuggestClick"-->
                    <!--                          placeholder="PRODUCT TYPE SEARCH"-->
                    <!--                          :class="{ 'is-invalid': submitted && $v.products.product_type.$error }"-->
                    <!--                      >-->
                    <!--                      </vue-simple-suggest>-->

<!--                    <Dropdown-->
<!--                        :v-model="products.product_type"-->
<!--                        :options="searchOption"-->
<!--                        label="name"-->
<!--                        value="id"-->
<!--                        track-by="name"-->
<!--                        v-on:selected="validateSelection"-->
<!--                        v-on:filter="getDropdownValues"-->
<!--                        :disabled="false"-->
<!--                        :class="{ 'is-invalid': submitted && $v.products.product_type.$error }"-->
<!--                        placeholder="PRODUCT TYPE SEARCH">-->
<!--                    </Dropdown>-->


                    <multiselect v-model="products.product_type"
                                 :options="listSubCategoryData"
                                 track-by="name" label="name" value="id"
                                 placeholder="PRODUCT TYPE SEARCH"
                                 :class="{ 'is-invalid': submitted && $v.products.product_type.$error }"
                                 @input="onSuggestClick"
                    >

                    </multiselect>




                    <div
                        v-if="submitted && !$v.products.product_type.required"
                        class="invalid-feedback"
                    >PRODUCT TYPE IS REQUIRED</div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label class="control-label">BRAND *</label>
                    <select class="form-control"
                            id="brandId"
                            v-model="products.brandId"
                            style="text-transform: uppercase !important;"

                            :class="{ 'is-invalid': submitted && $v.products.brandId.$error }"
                    >
                      <option value="">SELECT BRAND</option>
                      <option v-for="brand in brandData" :value="brand.id" :key="brand.id">{{brand.name}}</option>
                    </select>
                    <div
                        v-if="submitted && !$v.products.brandId.required"
                        class="invalid-feedback"
                    >BRAND IS REQUIRED
                    </div>
                  </div>
                </div>

              </div>


              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="sku">SKU *</label>
                    <input  v-model="products.sku[0]" type="text" class="form-control"
                            style="text-transform: uppercase !important;"
                            :class="{ 'is-invalid': submitted && $v.products.sku.$error }"
                            placeholder="SKU"
                    />
                    <div
                        v-if="submitted && !$v.products.sku.required"
                        class="invalid-feedback"
                    >PRODUCT SKU IS REQUIRED
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="product_price">PRICE *</label>
                    <input id="product_price" v-model="products.product_price[0]" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control"
                           :class="{ 'is-invalid': submitted && $v.products.product_price.$error }"
                           placeholder="PRICE"
                    />
                    <div v-if="submitted && $v.products.product_price.$error" class="invalid-feedback">
                      <span v-if="!$v.products.product_price.required">PRODUCT PRICE IS REQUIRED</span>
                      <!--                        <span v-if="!$v.products.product_price.decimal">PRODUCT PRICE IS MUST BE DECIMAL</span>-->
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label class="control-label">OFFER PRICE</label>
                    <input
                        v-model="products.compare_price[0]"
                        type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        class="form-control"
                        placeholder="OFFER PRICE"

                    />
                    <!--                      <div v-if="submitted && $v.products.compare_price.$error" class="invalid-feedback">-->
                    <!--                        <span v-if="!$v.products.compare_price.decimal">OFFER PRICE IS MUST BE DECIMAL </span>-->
                    <!--                      </div>-->
                  </div>
                </div>
              </div>

              <div class="row">

                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="cost_per_item">COST PER ITEM</label>
                    <input  v-model="products.cost_per_item[0]" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control" placeholder="COST PER ITEM"

                    />
                    <!--                      <div v-if="submitted && $v.products.cost_per_item.$error" class="invalid-feedback">-->
                    <!--                        <span v-if="!$v.products.cost_per_item.decimal">COST PER ITEM IS MUST BE DECIMAL </span>-->
                    <!--                      </div>-->
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="product_status">PRODUCT STATUS *</label>
                    <select class="form-control" v-model="products.product_status"
                            id="product_status">
                      <option value="1">ACTIVE</option>
                      <option value="0">IN-ACTIVE</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="size">SIZE</label>
                    <select class="form-control"
                            id="size"
                            v-model="products.sizeId[0]"
                            style="text-transform: uppercase !important;">
                      <option value="">SELECT SIZE</option>
                      <option v-for="size in sizeData" :value="size.id" :key="size.id">{{size.name}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">COLOR</label>
                    <select class="form-control"
                            id="colorId"
                            v-model="products.colorId[0]"
                            style="text-transform: uppercase !important;">
                      <option value="">SELECT COLOR</option>
                      <option v-for="color in colorData" :value="color.id" :key="color.id">{{color.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">AVAILABLE STOCK *</label>
                    <input id="available_stocka" v-model="products.available_stock[0]" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control" placeholder="AVAILABLE STOCK"
                           :class="{ 'is-invalid': submitted && $v.products.available_stock.$error }"
                    />
                    <div v-if="submitted && $v.products.available_stock.$error" class="invalid-feedback">
                      <span v-if="!$v.products.available_stock.required">AVAILABLE STOCK IS REQUIRED</span>
                      <!--                        <span v-if="!$v.products.available_stock.numeric">AVAILABLE STOCK IS MUST BE NUMBER</span>-->
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label" for="dimensionDS">DIMENSION</label>
                    <input id="dimensionDS" v-model="products.dimension[0]" type="text" class="form-control" placeholder="DIMENSION" />
                  </div>
                </div>
<!--                <div class="col-md-4">-->
<!--                  <div class="form-group">-->
<!--                    <label class="control-label" for="3dObj">3D OBJECT (GLB)</label>-->
<!--                    <input id="3dObj"  type="file" @change="selectedGlb"/>-->
<!--                  </div>-->
<!--                  <a target="_blank" :href="this.productData.glbObject" v-if="this.productData.glbObject">3D OBJECT FILE DOWNLOAD</a>-->


<!--                </div>-->

                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">3D OBJECT (GLB URL)</label>
                    <!--                      <input id="dimensionDS"  type="file" @change="selectedGlb"/>-->
                    <input id="glbFileUrl" v-model="products.glbObject" type="text" class="form-control" placeholder="GLB FILE URL"/>
                  </div>
                </div>


                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">3D OBJECT (ZIP)</label>
                    <input id="objectZip" type="file" @change="selectedZip"/>
                  </div>
                  <a target="_blank" :href="this.productData.glbZip" v-if="this.productData.glbZip">3D OBJECT ZIP DOWNLOAD </a> <a href="#"  v-if="this.productData.glbZip" @click="deleteZip"><i class="mdi delete mdi-trash-can font-size-14" style="color: red"></i></a>

                </div>
                <div class="col-md-4 mt-3">
                  <div class="form-group mt-3">
                    <div class="custom-control custom-checkbox">
                      <input id="invalidCheck" type="checkbox" v-model="products.preOrder[0]" true-value="1" false-value="0" class="custom-control-input" />
                      <label
                          class="custom-control-label"
                          for="invalidCheck"
                      >PRE-ORDER</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">HS CODE</label>
                    <input  v-model="products.hsCode[0]" type="text" class="form-control"
                            style="text-transform: uppercase !important;"
                            placeholder="HS CODE"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label>PRODUCT DESCRIPTION *</label>
                <div class="error">
                  {{this.descriptionError}}
                </div>
                <ckeditor v-model="products.description" :editor="editor" :class="{ 'is-invalid': submitted && $v.products.description.$error }">
                </ckeditor>
                <div v-if="submitted && $v.products.description.$error" class="invalid-feedback">
                  <span v-if="!$v.products.description.required">PRODUCT DESCRIPTION IS REQUIRED</span>
                </div>
              </div>
              <div class="tab-pane" id="product-img">
                <div class="row">
                  <div class="col-md-2">
                    <h4 class="card-title">PRODUCT IMAGES *</h4>
                    <input type="file" @change="onFileSelected"  ref="files" multiple="multiple"/>
                  </div>
                  <div class="col-md-3">
                    <h4 class="card-title">CAD BLOCK </h4>
                    <input type="file" ref="files" @change="onCadFileSelected"/>
                  </div>
                  <div class="col-md-3">
                    <h4 class="card-title">CAD BLOCK (DWG)</h4>
                    <input type="file"  ref="files1" accept=".dwg" @change="onCadFileDwgSelected" /><br/><br/>
                    <a target="_blank" :href="this.productData.variants[0].CadBlockDwg" v-if="this.productData.variants[0].CadBlockDwg"><u>CAD BLOCK FILE DOWNLOAD</u></a>&nbsp;
                    <a href="javascript:void(0);"  v-if="this.productData.variants[0].CadBlockDwg" @click="deleteCadBlockDwg(productData.variants[0].id)"><i class="mdi delete mdi-trash-can font-size-14" style="color: red"></i></a>
                  </div>
                  <div class="p-1 col-md-3 show-image" v-if="productData.variants[0].catBlock != ''">
                    <a target="_blank" :href="productData.variants[0].catBlock">
                      <img :src="productData.variants[0].catBlock"  class="avatar-lg"  />
                      <a
                              href="javascript:void(0);"
                              class="mr-2 delete text-danger"
                              v-b-tooltip.hover
                              @click="deleteCadBlock(productData.variants[0].id)"
                              title="DELETE"
                      >

                        <i class="mdi delete mdi-trash-can font-size-14"></i>
                      </a>
                    </a>
                  </div>

                </div>

                <!--                <div v-if="submitted && $v.products.images.$error" class="invalid-feedback">-->
                <!--                  <span v-if="!$v.products.images.required">PRODUCT IMAGE IS REQUIRED</span>-->
                <!--                </div>-->

                <!--              <vue-upload-multiple-image-->
                <!--                  @upload-success="uploadImageSuccess"-->
                <!--                  @before-remove="beforeRemove"-->
                <!--                  @edit-image="editImage"-->
                <!--                  idUpload="myIdUpload"-->
                <!--                  editUpload="myIdEdit"-->
                <!--                  :multiple=true-->

                <!--              ></vue-upload-multiple-image>-->
                <!--              <vue-dropzone-->
                <!--                  id="dropzone"-->
                <!--                  ref="myVueDropzone"-->
                <!--                  :use-custom-slot="true"-->
                <!--                  :options="dropzoneOptions"-->
                <!--                  @vdropzone-file-added="sendingFile"-->
                <!--              >-->
                <!--                <div class="dropzone-custom-content">-->
                <!--                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>-->
                <!--                  <h4>Drop files here or click to upload.</h4>-->
                <!--                </div>-->
                <!--              </vue-dropzone>-->
              </div>
              <div class="card-footer">
                <div class="border p-2 mt-3 container">
                  <template>
                    <div class="row" v-if="productData.variants[0]">
                      <div v-for="(data, index) in productData.variants[0].images" :key="index" class="p-1 col-md-2 show-image">
                        <img :src="data.imageUrl" class="avatar-lg" />
                        <a
                            href="javascript:void(0);"
                            class="mr-2 delete text-danger"
                            @click="deleteImageVarient1(data,index)"
                            v-b-tooltip.hover
                            title="DELETE"
                        >
                          <i class="mdi delete mdi-trash-can font-size-14"></i>
                        </a>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>


        <br/>
        <div class="card">
          <div class="card-body">
            <div class="tab-pane" id="variant">
              <h4 class="card-title">VARIANTS (OPTIONAL)</h4>


              <!--              <div v-for="(varient,index) in  productData.variants"  :key="index">-->
              <!--                &lt;!&ndash;                <h6 class="card-title-desc" align="center"><b>VARIANT {{k+2}}</b></h6>&ndash;&gt;-->
              <!--&lt;!&ndash;                <input type="hidden" v-bind:value="products.varients_data=(k+1+1)">&ndash;&gt;-->
              <!--                <div class="row">-->

              <!--                  <div class="col-md-3">-->
              <!--                    <div class="form-group">-->
              <!--                      <label class="control-label">SKU</label>-->
              <!--                      <input id="sku" :v-model="products.sku[index]" :value="varient.sku" type="text" class="form-control" placeholder="SKU" style="text-transform: uppercase !important;"-->
              <!--                      />-->

              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="col-md-3">-->
              <!--                    <div class="form-group">-->
              <!--                      <label class="control-label">AVAILABLE STOCK</label>-->
              <!--                      <input id="available_stock" :v-model="products.available_stock[index]" :value="varient.stock" type="number" class="form-control"  placeholder="AVAILABLE STOCK"/>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="col-md-3">-->
              <!--                    <div class="form-group">-->
              <!--                      <label class="control-label">DIMENSION</label>-->
              <!--                      <input id="dimension" :v-model="products.dimension[index]" :value="varient.dimension" type="text" class="form-control" placeholder="DIMENSION"/>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="col-md-3">-->
              <!--                    <div class="form-group">-->
              <!--                      <label class="control-label">SIZE</label>-->
              <!--                      <select class="form-control"-->
              <!--                              v-model="products.sizeId[k+1]"-->
              <!--                              style="text-transform: uppercase !important;">-->
              <!--                        <option value="">SELECT SIZE</option>-->
              <!--                        <option v-for="size in sizeData" :value="size.id" :key="size.id" >{{size.name}}</option>-->
              <!--                      </select>-->
              <!--                    </div>-->
              <!--                  </div>-->


              <!--                </div>-->

              <!--                <div class="row">-->
              <!--                  <div class="col-md-3">-->
              <!--                    <div class="form-group">-->
              <!--                      <label class="control-label">COLOR</label>-->
              <!--                      <select class="form-control"-->
              <!--                              v-model="products.colorId[k+1]"-->
              <!--                              style="text-transform: uppercase !important;">-->
              <!--                        <option value="">SELECT COLOR</option>-->
              <!--                        <option v-for="color in colorData" :value="color.id" :key="color.id">{{color.name}}</option>-->
              <!--                      </select>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="col-md-3">-->
              <!--                    <div class="form-group">-->
              <!--                      <label class="control-label">PRICE</label>-->
              <!--                      <input id="price"  :v-model="products.product_price[index]" :value="varient.price" type="number" class="form-control" placeholder="PRICE"/>-->
              <!--                    </div>-->
              <!--                  </div>-->

              <!--                  <div class="col-md-3">-->
              <!--                    <div class="form-group">-->
              <!--                      <label class="control-label">COMPARE AT PRICE</label>-->
              <!--                      <input id="compare_price"  :v-model="products.compare_price[index]" :value="varient.compareAtPrice" type="number" class="form-control" placeholder="COMPARE AT PRICE"-->

              <!--                      />-->

              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="col-md-3">-->
              <!--                    <div class="form-group">-->
              <!--                      <label class="control-label">COST PER ITEM</label>-->
              <!--                      <input id="cost_per_item"  :v-model="products.cost_per_item[index]" :value="varient.costPerItem" type="number" class="form-control" placeholder="COST PER ITEM"/>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="tab-pane" id="product-img1">-->
              <!--                  <h4 class="card-title">PRODUCT IMAGES</h4>-->
              <!--                  <input type="file" @change="executeDynamic(k+1)" :ref="'file'+(k+1)" :id="'fileId-'+(k+1)" multiple="multiple"/>-->


              <!--                  &lt;!&ndash;                <vue-dropzone&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    id="dropzone"&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    ref="myVueDropzone"&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    :use-custom-slot="true"&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    :options="dropzoneOptions"&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    @vdropzone-file-added="sendingFile1"&ndash;&gt;-->
              <!--                  &lt;!&ndash;                >&ndash;&gt;-->
              <!--                  &lt;!&ndash;                  <div class="dropzone-custom-content">&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>&ndash;&gt;-->
              <!--                  &lt;!&ndash;                    <h4>Drop files here or click to upload.</h4>&ndash;&gt;-->
              <!--                  &lt;!&ndash;                  </div>&ndash;&gt;-->
              <!--                  &lt;!&ndash;                </vue-dropzone>&ndash;&gt;-->
              <!--                </div>-->
              <!--              </div>-->

              <div v-for="(input,j) in dynamicFormData" :key="j" >
                <!--                <h6 class="card-title-desc" align="center"><b>VARIANT {{k+2}}</b></h6>-->
                <input type="hidden" v-bind:value="products.varients_data=(j+1+1)">
                <div class="row">

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">SKU</label>
                      <input id="skuOp" v-model="products.sku[j+1]" type="text" class="form-control" placeholder="SKU" style="text-transform: uppercase !important;"
                      />

                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">AVAILABLE STOCK</label>
                      <input id="available_stockOp" v-model="products.available_stock[j+1]" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control"  placeholder="AVAILABLE STOCK"/>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">DIMENSION</label>
                      <input id="dimensionOp" v-model="products.dimension[j+1]" type="text" class="form-control" placeholder="DIMENSION"/>
                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">SIZE</label>
                      <select class="form-control"
                              v-model="products.sizeId[j+1]"
                              style="text-transform: uppercase !important;">
                        <option value="">SELECT SIZE</option>
                        <option v-for="size in sizeData" :value="size.id" :key="size.id" >{{size.name}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">COLOR</label>
                      <select class="form-control"
                              v-model="products.colorId[j+1]"
                              style="text-transform: uppercase !important;">
                        <option value="">SELECT COLOR</option>
                        <option v-for="color in colorData" :value="color.id" :key="color.id">{{color.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">PRICE</label>
                      <input id="priceOp"  v-model="products.product_price[j+1]" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control" placeholder="PRICE"/>
                    </div>
                  </div>


                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">COMPARE AT PRICE</label>
                      <input id="compare_priceOp"  v-model="products.compare_price[j+1]" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control" placeholder="COMPARE AT PRICE"

                      />

                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">COST PER ITEM</label>
                      <input id="cost_per_itemOp"  v-model="products.cost_per_item[j+1]" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control" placeholder="COST PER ITEM"/>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">HS CODE</label>
                      <input  v-model="products.hsCode[j+1]" type="text" class="form-control"
                              style="text-transform: uppercase !important;"
                              placeholder="HS CODE"
                      />
                    </div>
                  </div>
                </div>


                <div class="tab-pane" id="product-img1">
                  <div class="row">
                    <div class="col-md-3">
                      <h4 class="card-title">PRODUCT IMAGES</h4>
                      <input type="file" @change="executeDynamic(j+1)" :ref="'file'+(j+1)" :id="'fileId-'+(j+1)" multiple="multiple"/>
                    </div>
                    <div class="col-md-3">
                      <h4 class="card-title">CAD BLOCK </h4>
                      <input type="file"   :id="'catFileId-'+(j+1)" @change="executeCadDynamic(j+1)"/>
                    </div>

                    <div class="col-md-3">
                      <h4 class="card-title">CAD BLOCK (DWG)</h4>
                      <input type="file"  accept=".dwg"  :id="'catFileDwgId-'+(j+1)" @change="executeCadDwgDynamic(j+1)" /><br/><br/>
                      <a target="_blank" :href="productData.variants[j+1].CadBlockDwg" v-if="productData.variants[j+1].CadBlockDwg"><u>CAD BLOCK FILE DOWNLOAD</u></a>&nbsp;
                      <a href="javascript:void(0);"  v-if="productData.variants[j+1].CadBlockDwg" @click="deleteCadBlockDwg(productData.variants[j+1].id)"><i class="mdi delete mdi-trash-can font-size-14" style="color: red"></i></a>
                    </div>


                    <div class="p-1 col-md-2 show-image" v-if="productData.variants[j+1].catBlock != ''">
                      <a target="_blank" :href="productData.variants[j+1].catBlock">
                        <img :src="productData.variants[j+1].catBlock"  class="avatar-lg"  />
                        <a
                                href="javascript:void(0);"
                                class="mr-2 delete text-danger"
                                v-b-tooltip.hover
                                @click="deleteCadBlock(productData.variants[j+1].id)"
                                title="DELETE"
                        >

                          <i class="mdi delete mdi-trash-can font-size-14"></i>
                        </a>
                      </a>
                    </div>
                  </div>



                  <br/>
                  <div class="card-footer">
                    <div class="border p-2 mt-3 container">
                      <template>
                        <div class="row">
                          <div v-for="(data, index) in productData.variants[j+1].images" :key="index" class="p-1 col-md-2 show-image">
                            <img :src="data.imageUrl" class="avatar-lg" />
                            <a
                                href="javascript:void(0);"
                                class="mr-2 delete text-danger"
                                @click="deleteImageVarients(data,index)"
                                v-b-tooltip.hover
                                title="DELETE"
                            >
                              <i class="mdi delete mdi-trash-can font-size-14"></i>
                            </a>
<!--                            <input class="delete" type="button" value="Delete" />-->
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>


                  <!--                <vue-dropzone-->
                  <!--                    id="dropzone"-->
                  <!--                    ref="myVueDropzone"-->
                  <!--                    :use-custom-slot="true"-->
                  <!--                    :options="dropzoneOptions"-->
                  <!--                    @vdropzone-file-added="sendingFile1"-->
                  <!--                >-->
                  <!--                  <div class="dropzone-custom-content">-->
                  <!--                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>-->
                  <!--                    <h4>Drop files here or click to upload.</h4>-->
                  <!--                  </div>-->
                  <!--                </vue-dropzone>-->
                </div>


                <div class="row">
                  <div class="col-md-6">

                  </div>

                  <div class="col-md-6" align="right">
                    <div class=" mt-4">
<!--                      <button class="btn btn-success waves-effect  mb-2 mr-1"  @click="add(j)" v-show="j == inputs.length-1" >Add</button>-->
                      <button
                          class="btn btn-danger mr-2 waves-effect waves-light  mb-2 mr-1"
                          @click="removeData(productData.variants[j+1].id,j)"
                      >Remove</button>
                    </div>
                  </div>
                </div>
              </div>



              <div v-for="(input,k) in inputs" :key="k" :v-if="dynamicVariants < 1">
                <!--                <h6 class="card-title-desc" align="center"><b>VARIANT {{k+2}}</b></h6>-->
                <input type="hidden" v-bind:value="products.varients_data=(k+1+1)">
                <div class="row">

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">SKU</label>
                      <input id="sku" v-model="products.sku[k+dynamicVariants]" type="text" class="form-control" placeholder="SKU" style="text-transform: uppercase !important;"
                      />

                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">AVAILABLE STOCK </label>
                      <input id="available_stock" v-model="products.available_stock[k+dynamicVariants]" type="number" min="0" step="1" class="form-control"  placeholder="AVAILABLE STOCK"/>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">DIMENSION</label>
                      <input id="dimension" v-model="products.dimension[k+dynamicVariants]" type="text" class="form-control" placeholder="DIMENSION"/>
                    </div>
                  </div>



                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">SIZE</label>
                      <select class="form-control"
                              v-model="products.sizeId[k+dynamicVariants]"
                              style="text-transform: uppercase !important;">
                        <option value="">SELECT SIZE</option>
                        <option v-for="size in sizeData" :value="size.id" :key="size.id" >{{size.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">COLOR</label>
                      <select class="form-control"
                              v-model="products.colorId[k+dynamicVariants]"
                              style="text-transform: uppercase !important;">
                        <option value="">SELECT COLOR</option>
                        <option v-for="color in colorData" :value="color.id" :key="color.id">{{color.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">PRICE</label>
                      <input id="price"  v-model="products.product_price[k+dynamicVariants]" type="number" min="0" step="1" class="form-control" placeholder="PRICE"/>
                    </div>
                  </div>


                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">COMPARE AT PRICE</label>
                      <input id="compare_price"  v-model="products.compare_price[k+dynamicVariants]" type="number" min="0" step="1" class="form-control" placeholder="COMPARE AT PRICE"

                      />

                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">COST PER ITEM</label>
                      <input id="cost_per_item"  v-model="products.cost_per_item[k+dynamicVariants]" type="number" min="0" step="1" class="form-control" placeholder="COST PER ITEM"/>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label">HS CODE</label>
                      <input  v-model="products.hsCode[k+dynamicVariants]" type="text" class="form-control"
                              style="text-transform: uppercase !important;"
                              placeholder="HS CODE"
                      />
                    </div>
                  </div>
                </div>


                <div class="tab-pane" id="product-img1">
                  <div class="row">
                    <div class="col-md-2">
                      <h4 class="card-title">PRODUCT IMAGES</h4>
                      <input type="file" @change="executeDynamic(k+1)" :ref="'file'+(k+dynamicVariants)" :id="'fileId-'+(k+dynamicVariants)" multiple="multiple"/>
                    </div>
                    <div class="col-md-3">
                      <h4 class="card-title">CAD BLOCK </h4>
                      <input type="file"   :id="'catFileId-'+(k+1)" @change="executeCadDynamic(k+dynamicVariants)"/>
                    </div>
                    <div class="col-md-3">
                      <h4 class="card-title">CAD BLOCK (DWG) </h4>
                      <input type="file"  accept=".dwg"  :id="'catFileDwgId-'+(k+1)" @change="executeCadDwgDynamic(k+1)"/>
                      <!--<input type="file"  accept=".dwg"  :id="'catFileDwgId-'+(k+1)" @change="executeCadDwgDynamic(k+1)"/>-->
                    </div>
                    <div class="col-md-4 mt-3">
                      <div class="form-group mt-3">
                        <div class="custom-control custom-checkbox">
                          <input id="invalidCheck2" type="checkbox" v-model="products.preOrder[k+1]" true-value="1" false-value="0" class="custom-control-input" />
                          <label
                                  class="custom-control-label"
                                  for="invalidCheck2"
                          >PRE-ORDER</label>
                        </div>
                      </div>
                    </div>
                  </div>




                  <!--                <vue-dropzone-->
                  <!--                    id="dropzone"-->
                  <!--                    ref="myVueDropzone"-->
                  <!--                    :use-custom-slot="true"-->
                  <!--                    :options="dropzoneOptions"-->
                  <!--                    @vdropzone-file-added="sendingFile1"-->
                  <!--                >-->
                  <!--                  <div class="dropzone-custom-content">-->
                  <!--                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>-->
                  <!--                    <h4>Drop files here or click to upload.</h4>-->
                  <!--                  </div>-->
                  <!--                </vue-dropzone>-->
                </div>


                <div class="row">
                  <div class="col-md-6">

                  </div>

                  <div class="col-md-6" align="right">
                    <div class=" mt-4">
                      <button class="btn btn-success waves-effect  mb-2 mr-1"  @click="add(k)" v-show="k == inputs.length-1" >Add</button>
                      <button
                          class="btn btn-danger mr-2 waves-effect waves-light  mb-2 mr-1"
                          @click="remove(k)" v-show="k || ( !k && inputs.length > 1)"
                      >Remove</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="card-footer" v-if="preview_dynamic.length">
            <div class="border p-2 mt-3 container">
              <template>
                <div class="row">
                  <div v-for="(data, index) in preview_dynamic" :key="index" class="p-1 col-md-2 show-image-dynamic">
                    <p>{{preview_dynamic[index].name}}</p>
                    <img :src="data.preview" class="avatar-lg" />
                    <a
                        href="javascript:void(0);"
                        class="mr-2 delete text-danger"
                        @click="deleteImage(index)"
                        v-b-tooltip.hover
                        title="DELETE"
                    >
                      <i class="mdi delete mdi-trash-can font-size-14"></i>
                    </a>
                  </div>
                </div>
              </template>
            </div>

          </div>

        </div>




        <div class="card">
          <div class="card-body">
            <div class="tab-pane" id="metadata">
              <div class="row">
                <div class="col-md-6">
                  <h4 class="card-title">SPECIFICATIONS</h4>
                  <table class="table-responsive">

                    <tr  v-for="(specification,index)  in specifications" :key="specification.specificationId" >
                      <td style="text-transform: uppercase !important;" >{{specification.specificationName}} &nbsp;</td>
                      <td>
                        <input type="hidden"  v-bind:v-model="products.specificationId[index] = specification.specificationId" class="form-control" >
                        <input type="text" v-model="products.specificationValue[index]"  class="form-control"  placeholder="SPECIFICATION VALUE" style="text-transform: uppercase !important;">
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <h4 class="card-title">FILTERS</h4>
                  <table class="table-responsive">

                    <tr  v-for="(filter,index)  in filters" :key="filter.filterId">
                      <td style="text-transform: uppercase !important;">{{filter.filterName}} &nbsp;</td>
                      <td>
                        <input type="hidden"  v-bind:v-model="products.filterId[index] = filter.filterId" class="form-control">
                        <input type="text" v-model="products.filterValue[index]"  class="form-control" placeholder="FILTER VALUE" style="text-transform: uppercase !important;">
                      </td>
                    </tr>



                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>





        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-9">
                <div class="form-group">
                  <h4 class="card-title">LOGISTICS INFORMATION</h4>

<!--                  <div-->
<!--                      v-for="(info, index1) in products.logistics_information.length "-->
<!--                      :key="index1"-->
<!--                      class="input wrapper flex items-center mt-2"-->
<!--                  >-->
<!--                    <input-->
<!--                        style="width: 600px; color: #505d69"-->
<!--                        type="text"-->
<!--                        v-model="products.logistics_information[index1]"-->
<!--                        class="h-10 rounded-lg outline-none p-2"-->
<!--                        placeholder=" ENTER LOGISTICS INFORMATION"-->

<!--                    />-->



<!--                    &lt;!&ndash;          Remove Svg Icon&ndash;&gt;-->
<!--                    <svg-->
<!--                        @click="removeInfo"-->
<!--                        xmlns="http://www.w3.org/2000/svg"-->
<!--                        viewBox="0 0 24 24"-->
<!--                        width="24"-->
<!--                        height="24"-->
<!--                        class="ml-2 cursor-pointer"-->
<!--                    >-->
<!--                      <path fill="none" d="M0 0h24v24H0z" />-->
<!--                      <path-->
<!--                          fill="#EC4899"-->
<!--                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"-->
<!--                      />-->
<!--                    </svg>-->
<!--                  </div>-->


                  <div
                      v-for="(input, index) in inputsInfo"
                      :key="index"
                      class="input wrapper flex items-center mt-2"
                  >
                    <input
                        style="width: 600px; color: #505d69"
                        type="text"
                        v-model="products.logistics_information[index]"
                        class="h-10 rounded-lg outline-none p-2"
                        placeholder=" ENTER LOGISTICS INFORMATION"

                    />

                    <!--          Add Svg Icon-->
                    <svg

                        @click="addField(index)"
                        v-show="index == inputsInfo.length-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="ml-2 cursor-pointer"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                          fill="green"
                          d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                      />
                    </svg>

                    <!--          Remove Svg Icon-->
                    <svg
                        @click="removeField(index, products.logistics_information[index])"
                        v-show="index || ( !index && inputsInfo.length > 1)"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="ml-2 cursor-pointer"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                          fill="#EC4899"
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                      />
                    </svg>
                  </div>

                </div>
              </div>



            </div>
          </div>
        </div>









        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="control-label">SEO TITLE</label>
                  <input id="seoTitle" v-model="products.seoTitle" type="text" class="form-control" placeholder="SEO TITLE" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="control-label">SEO DESCRIPTION</label>
                  <input id="seoDescription" v-model="products.seoDescription" type="text" class="form-control" placeholder="SEO DESCRIPTION" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="control-label">SEO KEYWORDS</label>
                  <input id="seoKeywords" v-model="products.seoKeywords" type="text" class="form-control" placeholder="SEO KEYWORDS" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12" align="center">
            <div class=" mb-4">
              <button
                  type="submit"
                  class="btn btn-primary mr-2 waves-effect waves-light"
                  v-on:click.prevent="handleSubmit"
              >UPDATE</button>
              <router-link to="/products"> <button class="btn btn-dark waves-effect">BACK</button> </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
div.show-image {
  position: relative;
  float:left;
  margin:5px;
}
div.show-image:hover img{
  opacity:0.5;
}
div.show-image:hover i {
  display: block;
}
div.show-image i {
  position:absolute;
  display:none;
}
div.show-image i.delete {
  top:5%;
  left:5%;
}
div.show-image-dynamic {
  position: relative;
  float:left;
  margin:5px;
}
div.show-image-dynamic:hover img{
  opacity:0.5;
}
div.show-image-dynamic:hover i {
  display: block;
}
div.show-image-dynamic i {
  position:absolute;
  display:none;
}
div.show-image-dynamic i.delete {
  top:35%;
  left:5%;
}

</style>